Vue.component('form-component', {
    data: function () {
        return {
            hasCaptcha: false,
            captchaExecuted: false
        }
    },
    mounted: function() {
        this.upload();
    },
    methods: {
        submit: function () {
            this.$validator.validateAll().then(result => {
                let form = this.$el;
                let analytics = null;

                if(form.dataset.analytics){
                    analytics = form.dataset.analytics;
                }

                if (result && analytics) {
                    if (!this.hasCaptcha || this.captchaExecuted) {
                        this.submitForm(form, analytics);
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                } else if (result) {
                    this.$root.submitForm(form);
                }
            });
        },

        upload: function(){
            let fileUpload = document.querySelectorAll('.form__file-input-wrapper');

            fileUpload.forEach(fieldwrapper => {

                let field = fieldwrapper.querySelector('.input--file');

                field.addEventListener('change',function(){
                    fieldwrapper.classList.add('form__file-input-wrapper--has-file');
                    field.classList.add('input--has-file');
                    fieldwrapper.querySelector('span').innerHTML = this.value.replace(/.*(\/|\\)/, '')
                });
            });
        },

        onCaptchaVerified: function () {
            this.captchaExecuted = true;
            this.submit();
        }
    },
});