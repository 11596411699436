Vue.component("projects-slider-component", {
    data: function () {
        return {
            projectsSlider: null
        }
    },
    mounted: function () {
        this.initSlider();
    },
    methods: {
        initSlider: function(){
            let slider = document.querySelector('.projects-slider');

            this.projectsSlider = new Flickity( slider, {
                prevNextButtons: false,
                pageDots: false,
                cellAlign: 'left',
                contain: true,
                wrapAround: true
            });
        },
        projectsSliderPrev: function() {
            this.projectsSlider.previous(true, false);
        },
        projectsSliderNext: function() {
            this.projectsSlider.next(true, false);
        }
    },
});