Vue.component("header-component", {
    data: function () {
        return {
            scrolled: false,
            menuExpanded: false
        }
    },
    mounted: function () {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();
        this.mobileHover();
    },
    methods: {
        mobileHover: function(){
            try {
                document.createEvent("TouchEvent");
                document.querySelectorAll('.navigation__list-item--has-sub').forEach( elm => {
                    elm.addEventListener('click', function(){

                        if(this.classList.contains('click')){
                            document.querySelectorAll('.navigation__list-item--has-sub.click').forEach( e => {
                                e.classList.remove('click');
                            });
                        } else {
                            document.querySelectorAll('.navigation__list-item--has-sub.click').forEach( e => {
                                e.classList.remove('click');
                            });

                            elm.classList.add('click');
                            return false;
                        }
                    });
                });
            } catch (e) {

            }
        },
        watchScrollPosition: function () {
            let that = this;
            let header = 0;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
            let hero = document.querySelector('.hero');

            if(hero){
                header = hero.clientHeight;
                header = header - document.querySelector('.header').clientHeight;
            }

            if (scrollTop >= header) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        },
    },
});