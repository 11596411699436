Vue.component("home-slider-component", {
    data: function () {
        let headerHeight = 0;
        if (document.querySelector('header')) {
            headerHeight = document.querySelector('header').clientHeight;
        }
        return {
            MoveToInstance: new MoveTo({
                tolerance: headerHeight,
                duration: 1200
            }),
        }
    },
    mounted: function () {
        this.initSlider();
    },
    methods: {
        initSlider: function(){
            let slider = document.querySelector('.home-slider__slides');
            let sliderNav = document.querySelector('.home-slider-nav');
            let sliderContent = document.querySelector('.home-slider-content');

            var _slider = new Flickity( slider, {
                prevNextButtons: false,
                pageDots: false
            });

            var _sliderNav = new Flickity( sliderNav, {
                asNavFor: slider,
                prevNextButtons: false,
                pageDots: false,
                draggable: false,
                // autoPlay: true
            });

            var _sliderContent = new Flickity( sliderContent, {
                asNavFor: sliderNav,
                prevNextButtons: false,
                pageDots: false,
                draggable: false,
                adaptiveHeight: true
            });
        }
    },
});